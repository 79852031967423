<template>
  <div class="cms-media-document-picker">
    <div class="document-picker-header ui-row --top">
      <file-uploader
        :path="path"
        max-files="1"
        @success="onUploadSuccess"
        @error="onUploadError"
      >
        <ui-field
          label="Archivo"
          style="text-align:left"
        >
          <ui-item
            style="text-align: left"
            icon="mdi:file-word"
            :text="blockProps.label || 'Subir archivo'"
          ></ui-item>
        </ui-field>
      </file-uploader>

      <ui-field
        style="flex:0"
        label="Visualizador"
        v-if="!!blockProps.value"
      >
        <select
          v-model="blockProps.provider"
          @change="emitInput"
        >
          <option value="google">Google</option>
          <option value="microsoft">Microsoft</option>
        </select>
      </ui-field>
    </div>

    <document-viewer
      v-if="blockProps.value && !isLoading"
      :value="blockProps.value"
      :provider="blockProps.provider"
    ></document-viewer>
  </div>
</template>

<script>
import DocumentViewer from './Viewer.vue';
import FileUploader from '@/modules/filesystem/components/File/FileUploader.vue';
import { UiItem, UiField } from '@/modules/ui/components';

export default {
  name: 'cms-media-document-picker',

  components: {
    DocumentViewer,
    FileUploader,
    UiItem,
    UiField
  },

  props: {
    path: {
      type: String,
      required: true
    },

    // objeto con las props para <document-viewer>:  {value, provider}
    value: {
      type: Object,
      required: false,
      default: null
    }
  },

  data() {
    return {
      blockProps: {
        value: null,
        provider: null
      },
      isLoading: false
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.blockProps = Object.assign(
          this.blockProps,
          { value: null, label: null, provider: 'google' },
          newValue
        );
      }
    }
  },

  methods: {
    onUploadSuccess(e) {
      this.blockProps.label = e[0].name;
      this.blockProps.value = e[0].url;
      this.emitInput();

      // Esperar unos milisegundos para actualizar el iframe correctamente
      this.isLoading = true;
      setTimeout(() => (this.isLoading = false), 800);
    },

    onUploadError(err) {
      alert('Error subiendo archivo');
      console.warn('Error subiendo archivo', err);
    },

    emitInput() {
      this.$emit('input', JSON.parse(JSON.stringify(this.blockProps)));
    }
  }
};
</script>